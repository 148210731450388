<template>
  <nav role="navigation" class="sidebar-bg-dark" id="navigation">
    <div class="scrollbar scrollbar-use-navbar scrollbar-bg-dark pt-1">
      <ul
        class="list list-unstyled list-bg-dark mb-0"
        v-for="(item, index) in menuItemsFilter"
        :key="index"
      >
        <li class="list-item">
          <ul class="list-unstyled">
            <li>
              <a
                href="#"
                class="list-link link-arrow text-uppercase"
                :class="{
                  'up transition active rotate link-current': item.active,
                }"
              >
                <span class="list-icon"> <i :class="item.icon"></i> </span
                >{{ item.name }}
              </a>

              <ul
                class="list-unstyled list-hidden"
                :style="{ display: item.active ? 'block' : 'none' }"
              >
                <li
                  v-for="(itemSub, index) in subMenu(item.subMenus)"
                  :key="index"
                >
                  <router-link
                    :to="itemSub.path"
                    class="list-link"
                    exact-active-class="link-current up active down"
                    disabled
                  >
                    {{ itemSub.name }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="list list-unstyled list-bg-dark mb-0">
        <li class="list-item">
          <ul class="list-unstyled">
            <li>
              <router-link to="/logout" class="list-link up"
                ><span class="list-icon"
                  ><i class="fa fa-sign-out-alt"></i></span
                >ออกจากระบบ</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style>
.sidebar-bg-dark {
  background-color: rgb(42, 45, 53);
}

#account-summary {
  color: white;
}

#sidebar.menu-min #account-summary {
  display: none;
}

#account-summary ul {
  font-family: Arial;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #e5e5e5;
  text-align: left !important;
}

#account-summary ul li {
  font-size: 14px;
}

#account-summary ul li span.prop {
  display: inline-block;
  width: 65px;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  margin-right: 4px;
}

#account-summary ul li span {
  font-weight: bold;
}
</style>

<script>
import $ from "jquery"
import PerfectScrollbar from "perfect-scrollbar"

export default {
  name: "Sidebar",
  data() {
    return {
      menuItems: [
        {
          name: "ตั้งค่า",
          icon: "fa fa-cog",
          active: false,
          subMenus: [
            {
              name: "ชนิดหวย",
              path: "/setting/lotto-type",
              allowUser: ["admin"],
            },
            {
              name: "งวดหวย",
              path: "/setting/lotto-round",
            },
          ],
        },
      ],
      user: this.$store.state.auth.user,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout")
      this.$router.push("/login")
    },
    subMenu(subMenu) {
      return subMenu.filter((item) => {
        if (item.allowUser) {
          return item.allowUser.includes(this.user.username)
        }

        return true
      })
    },
  },
  computed: {
    menuItemsFilter() {
      return this.menuItems
    },
  },
  mounted() {
    new PerfectScrollbar(".scrollbar", {
      wheelSpeed: 0.3,
    })

    this.menuItems.some((menuItem) => {
      let result = menuItem.subMenus.some((subMenu) => {
        return subMenu.path == this.$router.currentRoute.path
      })
      if (result) {
        menuItem.active = true
      }
    })

    $(".list-item").each(function () {
      let el = $(this)
      let linkHasClass = (className) => {
        return el.parent().find("a").hasClass(className)
      }
      if (linkHasClass("link-arrow")) {
        el.find("a").addClass("up")
        if (linkHasClass("link-current")) {
          let current = $(".link-current")
          current.addClass("active down")
          current.next("ul").show()
        }
      }
    })

    $(".link-arrow").on("click", function () {
      let el = $(this)
      el.addClass("transition").toggleClass("active rotate")

      !el.hasClass("link-current")
        ? el.addClass("link-current")
        : el.removeClass("link-current")

      el.next(".list-hidden").slideToggle("fast")
    })
  },
}
</script>